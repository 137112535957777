
.slider {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 16px;
  // gap: 18px;
  height: 64px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  user-select: none;
  svg {
    color: #000;
    cursor: pointer;
    &.disabled {
      color: #939393;
      cursor: not-allowed;
      pointer-events: none;
    }
  }
  a {
    text-decoration: none;
    padding: 4.5px 12px;
    border-radius: 100px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #000;
    cursor: pointer;
    &.active {
      background-color: #fa8c28;
      color: #ffffff;
    }
  }
  img {
    cursor: pointer;
  }
  @media screen and (max-width: 768px) {
    a {
      &:not(.mob-item) {
        display: none;
      }
    }
  }
}

.svg-inline--fa {
  display: var(--fa-display, inline-block);
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}
